body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #1f271b;
}

/* .search-box {
	background-color: #145C9E;
} */

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.search-btn:active {
	transform: translateY(2px);
}

.login {
	background-image: url("https://images.unsplash.com/photo-1513224502586-d1e602410265?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1331&q=80");
	background-repeat: no-repeat;
	width: 100%;
	background-size: cover;
	background-position: center;
}

.rotate {
	outline: 0;
	transition: ease 100ms;
	/* other styles... */
}

#hacky-input-sn {
	display: none;
	/* Hide the input */
}

#hacky-input-sn:checked + label.rotate {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

#hacky-input-name {
	display: none;
	/* Hide the input */
}

#hacky-input-name:checked + label.rotate {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
